<template>
    <div class="main-container">
      <div class="main-heading">
        <h1>Skills</h1>
      </div>
      <div class="content-container">
        <div class="table">
          <div class="table-row header-row alternate">
            <div class="cell"> Name </div>
            <div class="cell"> Id </div>
            <div class="cell"><a href="/skills/add" class="success">{{ $t('ADD') }}</a></div>
          </div>
          <div class="table-row" v-for="(skill, index) in skillsDisplayArr" v-bind:key="skill.id" :class="index % 2 === 0 ? '' : 'alternate'">
            <div class="cell">{{ skill.name }}</div>
            <div class="cell">{{ skill.id }}</div>
            <div class="cell">
              <a :href="'/skills/add?id=' + skill.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deleteUnit(skill.id, skill.index)" class="error">{{ $t('REMOVE') }}</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { watch, ref, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
  import { useStore } from "vuex";
  import moment from "moment";
  
  import dispatchMap from "@/constants/dispatchMap";
  
  export default {
    name: "Skills",
    setup() {
      const store = useStore();
      const usedPages = ["skillsIndex"];
      onBeforeMount(() => {
        usedPages.forEach(page => {
            dispatchMap[page].forEach((dispatchStr) => {
              store.dispatch(dispatchStr);
            })
        });
        }
      );
      const skillsArr = ref([])
      //const statusFilter = ref(1)
      const skillsDisplayArr = ref([])
  
      watch(() => store.getters['skills/getSkills'], value => {
        console.log(value)
        skillsArr.value = [ ...value ]
        skillsDisplayArr.value = JSON.parse(JSON.stringify(skillsArr.value))
      }, { immediate: true })
  
      onUpdated(() => store.dispatch("loader/loadingStatus", false));
      onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
      
      const deleteNews = id => {
        store.dispatch('news/deleteNews', { id })
      }
  
      /*const onStatusFilterChange = () => {
        newsDisplayArr.value = JSON.parse(JSON.stringify(newsArr.value.filter(x => {
          if (statusFilter.value == 1)
            return x.expiration_date >= Date.now()
          else 
            return x.expiration_date < Date.now()
        })))
      }*/
  
      
  
      return {
        skillsArr,
        moment,
        deleteNews,
        skillsDisplayArr,
        //statusFilter,
        //onStatusFilterChange
      }
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .main-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .content-container {
    width: 100%;
  }
  .table {
  
  }
  .table-row {
    padding: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
    text-align: start;
  }
  
  .table-row.alternate {
    background-color: lightgray;
  }
  .table-row.header-row {
    font-weight: 600;
    font-size: 1.125rem;
  }
  .cell {
    display: grid;
    padding: 0 10px;
  }
  
  
  button, a {
    font-size: 0.9rem;
    font-weight: 400;
    display: block;
    text-decoration: none;
    color: black;
    align-self: stretch;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  button.error, a.error {
    background-color: rgba(255, 0, 0, 0.15);
  }
  
  button.success, a.success {
    background-color: rgba(0, 255, 0, 0.15);
  }
  
  button.info, a.info {
    background-color: rgba(0, 0, 255, 0.15);
  }
  
  .cell.action-cell {
    display: flex;
  }
  
  .flex {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: space-around;
    align-items: center;
  }
  .flex-items {
    width: 150px;
    height:30px;
    margin: 5px;
    text-align: center;
  }
  .flex-items2 {
    width: 150px;
    height:35px;
    margin: 5px;
    text-align: center;
  }
  </style>